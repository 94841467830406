import axios from 'axios';
import { API_URL } from '../config';
import { authHeader } from './auth-header';

class EmployeeService {
    submitActs(empCode, ev = undefined, tmslot = undefined, acts = undefined) {
        return axios
            .post(API_URL + `employee/${empCode}/activities`,
                {
                    ev,
                    tmslot,
                    acts
                },
                { headers: { ...authHeader() } })
            .then(res => {
                return res.data;
            });
    }

    getCurrentActs(empCode) {
        return axios
            .get(API_URL + `employee/${empCode}/activities/current`,
                { headers: { ...authHeader() } })
            .then(res => {
                return res.data;
            });
    }
}

export default new EmployeeService();
