import React, { Component } from 'react';
import { Button, Row, Col, Card, Alert, Layout, Typography } from 'antd';
import { LoginOutlined } from '@ant-design/icons';
import AuthService from '../services/auth.service';

const { Content } = Layout;
const { Title, Text } = Typography;

// const lineUrl = 'https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1655212492&redirect_uri=https://1b985e41f3d4.ngrok.io/&state=12345abcde&scope=profile%20openid&nonce=09876xyz';
// const lineUrl = 'https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1655236684&redirect_uri=https://life-easy.transcode.co.th/&state=12345abcde&scope=profile%20openid&nonce=09876xyz';


export default class Login extends Component {
    constructor(props) {
        super(props);

        const params = new URLSearchParams(this.props.location.search);
        this.state = {
            code: params.get('code'),
            state: params.get('state'),
            error: params.get('error'),
            error_desc: params.get('error_description'),
            uiError: (<span></span>),
            isError: false,
            isLoggedIn: false,
            loading: true
        }

        this.goToLineLogin = this.goToLineLogin.bind(this);
    }

    goToLineLogin() {
        AuthService.getBasicConfig()
            .then(data => {
                window.location.assign(data.callback_uri);
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    isError: true,
                    uiError: <Alert style={{ background: 'rgba(255,0,0,0.2)', color: 'white', border: 0 }}
                        message={<Title level={3} style={{ color: 'white' }}>Error</Title>}
                        description='เชื่อมต่อ Server ไม่ได้'
                        type="error"
                        showIcon
                    />
                })
            });
        
    }

    componentDidMount() {
        const { code, state, error, error_desc } = this.state;

        // กรณีเพิ่ง redirect กลับจาก Line [สำเร็จ]
        if (code || error) {
            AuthService
                .loginByLine(code, state)
                .then(data => {
                    this.setState({
                        loading: true
                    });

                    // data
                    if (data.emp) {
                        setTimeout(() => {
                            this.props.history.push('/home');
                        }, 1000);
                    }
                    else {
                        setTimeout(() => {
                            this.props.history.push({
                                pathname: '/register',
                                state: { ...data, code, state }
                            });
                        }, 2000);
                    }

                },
                    err => {
                        this.setState({
                            loading: false,
                            isError: true,
                            uiError: <Alert style={{ background: 'rgba(255,0,0,0.2)', color: 'white', border: 0 }}
                                message={<Title level={3} style={{ color: 'white' }}>Error</Title>}
                                description={err.toString()}
                                type="error"
                                showIcon
                            />
                        })
                    });
        } else {
            // กรณีเข้าปกติ ถ้ามี Token อยู่แล้ว ไม่ต้องทำอะไร
            // ต้อง verify token อีกรอบ
            // .....
            setTimeout(() => {
                const user = AuthService.getCurrentUser();
                if (user) {
                    this.props.history.push('/home');
                }
                else {
                    this.setState({ loading: false });
                }
            });
        }
    }

    render() {
        const { loading, isLoggedIn, code, isError, uiError } = this.state;
        return (
            // <Layout className="Home-Layout" style={{ backgroundImage: `url(assets/bkk1-min-blur.jpg)` }}>
            <Layout className="Home-Layout">
                <Content style={{ textAlign: "center", height: '100vh' }}>

                    <Row justify="center" align="middle" style={{ height: '100%' }}>
                        <Card title={<Title style={{ color: 'white' }}>Transcode LiFE</Title>}
                            style={{ background: 'rgba(0,0,0,0.5)', margin: '50px', borderRadius: '5px', border: 0, minWidth: '350px' }}
                            loading={loading}>

                            <Col span={24}>
                                <Button block ghost size="large" style={{ height: '64px' }} icon={<LoginOutlined />} onClick={x => this.goToLineLogin()}>
                                    Login</Button>
                            </Col>
                            {
                                isError &&
                                <Col span={24}>
                                    {uiError}
                                </Col>
                            }
                            <Col span={24} style={{ margin: '5px' }}>
                                <Text style={{ color: 'white' }}>version: 0.0.1-Beta</Text>
                            </Col>
                        </Card>
                    </Row>
                </Content>
            </Layout>
        );
    }

    // render() {
    //     const { loading, isLoggedIn, code, isError, uiError } = this.state;
    //     return (
    //         <Layout className="Home-Layout" style={{ backgroundImage: `url(assets/bkk1.jpg)`}}>
    //             <Content style={{ textAlign: "center", height: '100vh' }}>
    //                 <Row justify="center" align="middle" style={{ height: '100%' }}>
    //                     <Spin size="large" spinning={loading}>
    //                         {!loading && <Col span={24} style={{width:'100px'}}>
    //                             <Button block ghost size="large" style={{ height: '64px' }} onClick={x => this.goToLineLogin()}>
    //                                 Login
    //                             </Button>
    //                         </Col>}
    //                         {
    //                             isError &&
    //                             <Col span={24}>{uiError}</Col>
    //                         }
    //                     </Spin>
    //                 </Row>
    //             </Content>
    //         </Layout>
    //     );
    // }
}