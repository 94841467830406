import axios from 'axios';
import { API_URL } from '../config';
import { authHeader } from './auth-header';

class SearchService {
    search(q) {
        const ur = encodeURI(`?q=${q}`);
        return axios
            .get(API_URL + `search${ur}`,
                { headers: { ...authHeader() } })
            .then(res => {
                return res.data;
            });
    }
}

export default new SearchService();
