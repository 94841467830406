import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Button, Row, Col, Input, Card, Space, Result, Typography, Select, Divider, Tooltip } from 'antd';
import { ArrowLeftOutlined, VerticalAlignTopOutlined } from '@ant-design/icons';
import SearchService from '../services/search.service';
import EmployeeService from '../services/employee.service';
import AuthService from '../services/auth.service';

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

export default class ReportActivity extends Component {
    constructor(props) {
        super(props);

        this.state = {
            empCode: AuthService.getCurrentUserId(),
            title: this.props.location.state.title,
            ev: this.props.location.state.ev,
            tmslot: this.props.location.state.tmslot,
            loading: false,
            success: false,
            data: [],
            acts: [{}, {}, {}],
        }

        this.onSearch = this.onSearch.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSearch = value => {
        if (value) {
            SearchService.search(value).then(data => {
                this.setState({ data });
            });
        } else {
            this.setState({ data: [] });
        }
    };

    onChange = (value, indx) => {
        const { acts } = this.state;
        let actsNew = acts;
        actsNew[indx] = {
            ...acts[indx],
            ...value
        };
        console.log(value)
        this.setState({ acts: actsNew });
    };

    onSubmit = () => {
        const { empCode, ev, tmslot, acts } = this.state;

        let actsNew = [];
        for (let a of acts) {
            if (a.proj && a.desc) actsNew.push(a);
        }

        this.setState({ loading: true });

        EmployeeService
            .submitActs(empCode, ev, tmslot, actsNew)
            .then(res => {
                this.setState({ loading: false, success: true });
            })
            .catch(err => {
                alert(`ทำรายการไม่สำเร็จ! \r\n${err.toString()}`)
                this.setState({ loading: false });
            });
    }

    // render() {
    //     return (
    //         <Row justify="space-between" align="middle"
    //             style={{ display: 'flex', 'minHeight': '80vh', 'top': 0, 'width': '100%', 'overflow': 'hidden' }}>

    //             <Col xs={{ offset: 2, span: 20 }} md={{ offset: 6, span: 12 }} xl={{ offset: 8, span: 8 }} align="start">
    //                 <PageHeader
    //                     onBack={() => { this.props.history.push('/home') }}
    //                     title="Back"
    //                     subTitle="09:00-11:00"
    //                 />
    //                 <ActForm/>
    //             </Col>
    //         </Row>
    //     );
    // }

    render() {
        const { title, loading, success, acts } = this.state;

        const options = this.state.data.map(d => <Option key={d.proj}>
            <Tooltip title={`${d.name}`}>
                <Space direction="vertical" size="small">
                    <Space size="small">
                        <Text code>{d.proj}</Text> {d.abbv && <Text>{`${d.abbv}`}</Text>}
                    </Space>
                    <Text type="secondary">{d.name}</Text>
                </Space>
            </Tooltip>
        </Option>);

        return (
            <Row className="Home-Content-Container">
                {
                    success ? (<Col><Result
                        style={{ backgroundColor: "rgba(255, 255, 255, 0.8)", borderRadius: "10px", width: 300 }}
                        status="success"
                        title="บันทึกสำเร็จ!"
                        extra={[
                            <Link to="/home">
                                <Button block key="buy" shape="round">OK</Button>
                            </Link>
                        ]} /></Col>) :

                        <Col xs={20} sm={18} md={16} lg={10} xl={8} xxl={6}
                            style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', borderRadius: "10px", padding: "10px" }}>
                            <Card loading={loading}>
                                <Space>
                                    <Link to='/home'><Button block icon={<ArrowLeftOutlined />} shape="circle" size="middle" /></Link>

                                </Space>
                                <Divider orientation="left" plain>รายการที่ 1</Divider>
                                <Row>
                                    <Col span={24}>
                                        <Select
                                            showSearch
                                            value={this.state.value}
                                            placeholder='พิมพ์ค้นหา รายการที่ 1'
                                            style={{ width: '100%', minWidth: 200 }}
                                            defaultActiveFirstOption={false}
                                            showArrow={false}
                                            filterOption={false}
                                            onSearch={this.onSearch}
                                            onChange={x => this.onChange({ proj: x }, 0)}
                                            notFoundContent={null}>
                                            {options}
                                        </Select>
                                    </Col>
                                    <Col span={24}>
                                        <TextArea style={{ width: '100%', minWidth: 200 }} placeholder="กรอกรายละเอียด รายการที่ 1" allowClear showCount maxLength={100} autoSize={{ minRows: 1, maxRows: 2 }}
                                            onChange={x => this.onChange({ desc: x.target.value }, 0)} value={acts[0].desc} />
                                    </Col>
                                </Row>

                                <Divider orientation="left" plain>รายการที่ 2</Divider>
                                <Row>
                                    <Col span={24}>
                                        <Select
                                            showSearch
                                            value={this.state.value}
                                            placeholder='พิมพ์ค้นหา รายการที่ 2'
                                            style={{ width: '100%', minWidth: 200 }}
                                            defaultActiveFirstOption={false}
                                            showArrow={false}
                                            filterOption={false}
                                            onSearch={this.onSearch}
                                            onChange={x => this.onChange({ proj: x }, 1)}
                                            notFoundContent={null}>
                                            {options}
                                        </Select>
                                    </Col>
                                    <Col span={24}>
                                        <TextArea style={{ width: '100%', minWidth: 200 }} placeholder="กรอกรายละเอียด รายการที่ 2" allowClear showCount maxLength={100} autoSize={{ minRows: 1, maxRows: 2 }}
                                            onChange={x => this.onChange({ desc: x.target.value }, 1)} value={acts[1].desc} />
                                    </Col>
                                </Row>

                                {/* <Row justify="center">
  <Tooltip title="เพิ่มรายการ">
      <Button shape="circle" type="dashed" icon={<PlusOutlined />} />
  </Tooltip>
</Row> */}

                                <Divider></Divider>
                                <Row>
                                    <Button type="primary" block icon={<VerticalAlignTopOutlined />} size='large' loading={loading}
                                        onClick={this.onSubmit}>
                                        เวลา {title}
                                    </Button>
                                </Row>
                            </Card>
                        </Col>

                }
            </Row>
        );
    }

};