import React, { Component } from 'react';
import { Button, Row, Drawer, Layout } from 'antd';
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { MenuOutlined, LogoutOutlined } from '@ant-design/icons';
import AuthService from '../services/auth.service';
import Home from './Home';
import ReportActivity from './ReportActivity';
import Test from './Test';

const { Content } = Layout;


export default class HomeContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // user: AuthService.getCurrentUser(),
            visible: false
        }

        this.showDrawer = this.showDrawer.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onLogout = this.onLogout.bind(this);
    }

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    onLogout() {
        AuthService.logout();
        this.props.history.push('/');
    }




    render() {
        // const { user } = this.state;

        return (
            <Layout className="Home-Layout">
                {/* <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                    <Menu theme="dark" mode="horizontal" style={{ float: 'right' }}>
                        <Menu.Item key="1" onClick={this.onLogout}>Logout</Menu.Item>
                    </Menu>
                </Header> */}
                <Content className="Home-Content">
                    <Button style={{ position: 'fixed', zIndex: 1, float: 'right', margin: '10px 0px 10px 0px' }}
                        icon={<MenuOutlined />} size="large"
                        onClick={this.showDrawer}
                    />
                    <Drawer
                        // title="Multi-level drawer"
                        placement='bottom'
                        closable={false}
                        onClose={this.onClose}
                        visible={this.state.visible}
                    >
                        <Row align="middle" justify="center">
                            <Button block danger icon={<LogoutOutlined />} onClick={this.onLogout}>Logout</Button>
                        </Row>
                    </Drawer>
                    <BrowserRouter basename='/home'>
                        <Switch>
                            <Route path='/submit-activities' component={ReportActivity} />
                            <Route path='/test' component={Test} />

                            <Route path='/' component={Home} />

                        </Switch>
                    </BrowserRouter>
                </Content>

                {/* <Footer className="Home-Footer">Transcode Co.,Ltd.</Footer> */}


            </Layout>

            // <Row justify="space-between" align="start">

            //     <Row span={24} align="center" style={{ 'position': 'fixed', 'width': '100%', 'top': 0, zIndex: 1, background: 'green' }}>
            //         <Space direction="vertical" size="small">
            //             <Title level={3}>สวัสดี {user.emp?.display_name}</Title>
            //             <Title level={5}>{new Date().toString()}</Title>
            //         </Space>
            //     </Row>

            //     <Row span={24}>
            //         <Col span={24}>
            //             <BrowserRouter basename='/home'>
            //                 <Switch>
            //                     <Route path='/submit-activities' component={ReportActivity} />
            //                     <Route path='/test' component={Test} />

            //                     <Route path='/' component={Home} />

            //                 </Switch>
            //             </BrowserRouter>
            //         </Col>
            //     </Row>

            //     <Row span={24} style={{ 'position': 'fixed', 'width': '100%', 'bottom': 0 }}>
            //         <Button onClick={this.onLogout} style={{ 'width': '100%' }}>Logout</Button>
            //     </Row>

            // </Row>



        );
    }
}