import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col, Input, Card, Space, Alert, Result } from 'antd';
import { LoginOutlined } from '@ant-design/icons';

import AuthService from '../services/auth.service';

export default class Register extends Component {
    constructor(props) {
        super(props);

        this.form = React.createRef();

        const params = new URLSearchParams(this.props.location.search);
        this.state = {
            // code: params.get('code'),
            // state: params.get('state'),
            error: params.get('error'),
            error_desc: params.get('error_description'),
            isLoggedIn: false,
            loading: false,
            success: false,
            prompErr: null,
            prompErrDialog: null,

            accId: this.props.location.state.acc_id,
            code: this.props.location.state.code,
            state: this.props.location.state.state
        }
    }

    onRegister = (values) => {
        const { accId, code, state, loading } = this.state;

        if (loading) return;

        this.setState({
            loading: true
        });

        AuthService
            .register(accId, code, state, values.emp_code, values.display_name)
            .then(res => {
                if (res.code_err == 'ERR_NO_EMP') {
                    this.setState({
                        loading: false,
                        prompErr: {},
                        prompErrDialog: (<Space direction="vertical">
                            <Alert
                                message='ไม่พบข้อมูลรหัสที่ท่านกรอก'
                                description='กรุณาติดต่อแผนกบุคคล'
                                type="error"
                                showIcon
                            />
                            {/* <Button icon={<ArrowLeftOutlined />} type="default" htmlType="submit" onClick={() => this.setState({ prompErr: null })}>กรอกข้อมูลใหม่</Button> */}
                            <Link to='/login'>
                                <Button block type="default" htmlType="submit" icon={<LoginOutlined />}>
                                    LoginOutlined
                                    </Button>
                            </Link>
                        </Space>)
                    });
                    return;
                } else if (res.code_err == 'ERR_NO_ACC') {
                    this.setState({
                        loading: false,
                        prompErr: {},
                        prompErrDialog: (<Space direction="vertical">
                            <Alert
                                message='ไม่พบข้อมูล Line Account'
                                description='กรุณา Login อีกครั้ง'
                                type="error"
                                showIcon
                            />
                            {/* <Button icon={<ArrowLeftOutlined />} type="default" htmlType="submit" onClick={() => this.setState({ prompErr: null })}>กรอกข้อมูลใหม่</Button> */}
                            <Link to='/login'>
                                <Button block type="default" htmlType="submit" icon={<LoginOutlined />}>
                                    LoginOutlined
                                    </Button>
                            </Link>
                        </Space>)
                    });
                    return;
                }
                // else if (res.code_err == 'NO_LINE_CODE') {
                //     this.setState({
                //         prompErr: {},
                //         prompErrDialog: (
                //             <Space direction="vertical">
                //                 <Alert
                //                     message='รหัสจาก Line ไม่ถูกต้อง'
                //                     description='กรุณา Login อีกครั้ง'
                //                     type="error"
                //                     showIcon
                //                 />
                //                 <Button type="default" htmlType="submit">
                //                     <Link to='/login'>Login</Link>
                //                 </Button>
                //             </Space>
                //         )
                //     });
                //     return;
                // }

                // CALL Login
                setTimeout(() => {
                    this.setState({ loading: false, success: true });
                }, 1000);
                // this.props.history.push('/home');

            }, err => {
                this.setState({
                    loading: false,
                    prompErr: {},
                    prompErrDialog: (
                        <Space direction="vertical">
                            <Alert
                                message='ERROR โปรดติดต่อ Admin'
                                description={err.toString()}
                                type="error"
                                showIcon
                            />
                            <Link to='/login'>
                                <Button block type="default" htmlType="submit" icon={<LoginOutlined />}>
                                    LoginOutlined
                                    </Button>
                            </Link>
                        </Space>
                    )
                });
            });
    }

    componentDidMount() {
    }

    render() {
        const { loading, success, prompErr, prompErrDialog } = this.state;

        const registerForm = (
            <Form ref={this.form}
                layout="vertical"
                onFinish={this.onRegister}>
                <Form.Item name="emp_code" label="รหัสพนักงาน" required
                    rules={[{ required: true, message: 'Please input Employee Code!' }]}
                    tooltip={
                        <div>
                            <div>รหัสพนักงาน (Employee Code)</div>
                            <div>จำนวน 5 ตัวอักษร</div>
                        </div>}>
                    <Input placeholder="รหัสพนักงาน 5 ตัวอักษร" />
                </Form.Item>
                <Form.Item name="display_name" label="ชื่อเรียกโดยย่อ" required
                    rules={[{ required: true, message: 'Please input Display Name!' }]}
                    tooltip={
                        <div>
                            <div>ชื่อเรียกโดยย่อ (Display Name)</div>
                            <div>ไม่เกิน 15 ตัวอักษร</div>
                        </div>}>
                    <Input placeholder="ชื่อเรียกต้นเองโดยย่อ" />
                </Form.Item>
                <Form.Item shouldUpdate={true}>
                    {() => (
                        <Button block type="primary" htmlType="submit" disabled={
                            this.form.current && (!this.form.current.isFieldsTouched(true) ||
                                this.form.current.getFieldsError().filter(({ errors }) => errors.length).length)}>
                            Submit
                        </Button>
                    )}
                </Form.Item>
            </Form>
        );

        return (
            <Row justify="space-between" align="middle">
                <Col span={24}>
                    {/* <Space direction="vertical">
                        {prompErr ? prompErrDialog : registerForm}
                    </Space> */}
                    <Card loading={loading} style={{ minWidth: '300px' }}>
                        {prompErr && prompErrDialog}
                        {(!prompErr && !success) && registerForm}
                        {success && <Result
                            status="success"
                            title="ลงทะเบียนสำเร็จ!"
                            subTitle="กรุณา Login อีกครั้ง เพื่อเข้าสู่ระบบ"
                            extra={
                                <Link to='/'><Button icon={<LoginOutlined />}>Login</Button></Link>
                            } />}
                    </Card>
                </Col>
            </Row>
        );

    }
}

