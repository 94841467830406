import React, { Component } from 'react';
import { Select, Space, Tooltip, Typography } from 'antd';
import SearchService from '../services/search.service';

const { Option } = Select;
const { Text } = Typography;

export default class Test extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            value: undefined,
        };

        this.handleSearch = this.handleSearch.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
    }

    handleSearch = value => {
        if (value) {
            SearchService.search(value).then(data => {
                console.log(data)
                this.setState({ data });
            });
        } else {
            this.setState({ data: [] });
        }
    };

    handleChange = value => {
        this.setState({ value });
    };

    render() {
        // const options = this.state.data.map(d => <Option key={d.proj}>{d.abbv ? `(${d.abbv}) ${d.name}` : d.name}</Option>);
        const options = this.state.data?.map(d => <Option key={d.proj}>
            <Tooltip title={`${d.name}`}>
                <Space direction="vertical" size="small">
                    <Space size="small">
                        <Text code>{d.proj}</Text> {d.abbv && <Text>{`${d.abbv}`}</Text>}
                    </Space>
                    <Text type="secondary">{d.name}</Text>
                </Space>
            </Tooltip>
        </Option>);

        return (
            <Select
                showSearch
                value={this.state.value}
                placeholder='Search'
                style={{ width: 200 }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={this.handleSearch}
                onChange={this.handleChange}
                notFoundContent={null}>
                {options}
            </Select>
        );
    }
}